/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~animate.css/animate.min.css";
@font-face {
  font-family: "ShipporiAntique"; //This is what we are going to call
  src: url("./assets/fonts/ShipporiAntique-Regular.ttf");
}
@font-face {
  font-family: "Dosis"; //This is what we are going to call
  src: url("./assets/fonts/Dosis-VariableFont_wght.ttf");
}
ion-popover.menu {
  --width: 170px;
  --max-width: 100%;
}

.popover_setting .popover-content {
  --min-width: 90% !important;
  --max-height: 400px !important;
  top: 10px !important;
  left: 20px !important;
  transform-origin: right top !important;

  border-radius: 5px;
}
@media only screen and (min-width: 650px) {
  .popover_setting .popover-content {
    --max-height: 600px !important;

  }
}
@media only screen and (min-width: 1024px) {
  .popover_setting .popover-content {
    --max-height: 85% !important;

  }
}
@media only screen and (min-width: 1324px) {
  .popover_setting .popover-content {
    --max-height: 100% !important;

  }
}

// input:-webkit-autofill,
// input:-webkit-autofill:focus {
//   box-shadow: 0 0 0 0 white inset;
//   -webkit-text-fill-color: rgb(49, 49, 49);
//   padding-bottom: 0 !important;
//   font-size: 15px;
// }
input {
  height: 50px;
  padding: 3%;
}
input:-webkit-autofill {
  height: 0px;
  -webkit-box-shadow: 0 0 0 10px white inset;
  -webkit-text-fill-color: black;
  font-weight: 500px;
}

input:-webkit-autofill:focus {
  height: 0px;
  -webkit-box-shadow: 0 0 0 10px white inset;
  -webkit-text-fill-color: black;
  font-weight: 500px;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

ion-spinner {
  --color: var(--ion-color-primary);
}
.url-video-dia {
  text-decoration: underline;
  // color: rgb(20, 77, 131) !important;
  cursor: pointer;
  // font-weight: bold;
  // font-family: ShipporiAntique !important;
}

.url-video-dia:visited {
  text-decoration: underline;
}

ion-alert.custom-alert-reason > .alert-wrapper{
  width: 100%!important;
}
